import { benTop } from "./assets/images";

import './HomePage.scss';

export const HomePage = () => {

    return (
        <div className="HomePage">

            <div className="header">

                <div className="left">
                    <h1>
                        Ben Dobschin
                    </h1>
                    <h2>
                        Creative Developer
                    </h2>
                </div>

                {/* <div className="right">
                    <div className="item">
                        About
                    </div>
                    <div className="item">
                        Technologies
                    </div>
                    <div className="item">
                        Projects
                    </div>
                </div> */}

            </div>

            <div className="content-top">

                <div className="left">
                    <img
                        src={benTop}
                        alt="Ben"
                    />
                </div>

                <div className="right">

                    <h2>
                        Hey there,
                    </h2>

                    <h1>
                        I'm <div>Ben</div>.
                    </h1>

                    <p>
                        I'm a creative <code>web | app | game</code>  developer and full-stack software engineer with a passion for leading the development of interactive, experiential solutions and digital engagement campaigns, from initial ideation to public launch and beyond. {/* currently employed as lead developer at a digital consultancy. */}
                    </p>

                    {/* <p>
                       With degrees in physics and computer science (BSc) and game development and production (Msc), I currently put my skills to use as lead developer at a digital consultancy, servicing private and public sector clients, including the European Union, Heathrow, BT, EE, Vodafone, EDF, Lloyds and SAP.
                    </p> */}

                    <p>
                        In my spare time, I enjoy game development, pixel art, {/* 3D modelling, */} gym, cycling and riding. Oh, and in case you didn't know, <code>dogs&nbsp;&gt;&nbsp;cats</code>.
                    </p>

                    <div className="tags">
                        <div className="tag tag-creative">
                            Web development
                        </div>
                        <div className="tag tag-creative">
                            Game development
                        </div>
                        <div className="tag tag-creative">
                            UI/UX design
                        </div>
                        <div className="tag tag-creative">
                            Pixel art & animation
                        </div>
                        <div className="tag tag-creative">
                            3D modelling
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-below">

                <div className="wrapper">

                    <div className="left">
                        <div className="heading">
                            Full-stack web
                        </div>
                        <p>
                            Having build websites for the past two decades, I have a strong foundation in the core web technologies, and expert skills in front-end and back-end JavaScript, along with a keen eye for styling and responsive design.
                        </p>
                        <div className="tags">
                            <div className="tag">
                                TypeScript
                            </div>
                            <div className="tag">
                                JavaScript
                            </div>
                            <div className="tag">
                                React
                            </div>
                            <div className="tag">
                                React Native
                            </div>
                            <div className="tag">
                                HTML 5
                            </div>
                            <div className="tag">
                                CSS 3
                            </div>
                            <div className="tag">
                                SCSS
                            </div>
                            <div className="tag">
                                Node.js
                            </div>
                            <div className="tag">
                                Express
                            </div>
                            <div className="tag">
                                MongoDb
                            </div>
                            <div className="tag">
                                i18n
                            </div>
                            <div className="tag">
                                a11y
                            </div>
                        </div>
                    </div>

                    <div className="right">
                        <div className="heading">
                            Games & Creative
                        </div>
                        <p>
                            With a strong creative drive, and a keen eye for visual detail, I specialise in interactive, experiential solutions and games, whether featuring pixel art, vector illustrations, or 3D models.
                        </p>
                        <div className="tags">
                            <div className="tag tag-creative">
                                Three.js
                            </div>
                            <div className="tag tag-creative">
                                Pixi.js
                            </div>
                            <div className="tag tag-creative">
                                GLSL
                            </div>
                            <div className="tag tag-creative">
                                Unity
                            </div>
                            <div className="tag tag-creative">
                                C#
                            </div>
                            <div className="tag tag-creative">
                                Aseprite
                            </div>
                            <div className="tag tag-creative">
                                Blender
                            </div>
                            <div className="tag tag-creative">
                                After Effects
                            </div>
                            <div className="tag tag-creative">
                                Figma
                            </div>
                            {/* <div className="tag tag-creative">
                            ClickUp
                        </div> */}
                            {/* <div className="tag">
                            i18n
                        </div>
                        <div className="tag">
                            a11y
                        </div> */}
                        </div>
                    </div>

                    <div className="right">
                        <div className="heading">
                            Project Management
                        </div>
                        <p>
                            As part of my role as lead developer, I assess functional and non-functional requirements, plan scope, estimate tasks, set goals, track progress, collaborate across teams, and liaise with key stakeholders.
                        </p>
                        <div className="tags">
                            <div className="tag tag-creative">
                                Trello
                            </div>
                            <div className="tag tag-creative">
                                Jira
                            </div>
                            <div className="tag tag-creative">
                                ClickUp
                            </div>
                            <div className="tag tag-creative">
                                Milanote
                            </div>
                            <div className="tag tag-creative">
                                Notion
                            </div>
                            <div className="tag tag-creative">
                                Mural
                            </div>
                            <div className="tag tag-creative">
                                FigJam
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}
